<div class="banner_area_type3 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="banner_section_title align-center">
                  <div class="section_sub_title">
                      <p>Booking</p>
                  </div>
                  <h1>Find Your Desire Room</h1>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink='/'>
                              Home
                          </a>
                      </li>
                      <li class="active">
                        Booking
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
<section class="main-content bkng">
  <div class="container-fluid">
    <div class="content">
      <div class="booking-tabs-list">
        <ul class="nav nav-tabs" id="myTab3" role="tablist">
          <li class="nav-item">
            <a class="nav-link active"> <span class="nom"></span>CHOSE ROOM</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span> BOOKING</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span> CHECKOUT</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"><span class="nom"></span> THANK YOU</a>
          </li>
        </ul>
      </div>
      <!--booking-tabs-list end-->
      <div class="row">
        <div class="col-12">


      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="choose-room" role="tabpanel" aria-labelledby="choose-tab">
          <div class="">
            <div class="row">
              <div class="col-lg-4">
                <div class="sidebar">
                  <div class="avail-sec">
                    <!-- <form> -->
                      <div class="row mb-2">
                        <div class="col-lg-12 col-sm-12">

                              <!-- <div class="form-group">
                                 <label for="city" class="text-white">Destination/City name</label>
                                 <input type="text" placeholder="Search City"
                                    class="form-control form-control-alternative" value="{{bookingCity}}"
                                    [(ngModel)]="bookingCity" readonly="readonly">
                              </div> -->
                              <div class="form-group hidden">
                                 <div class="input-group">
                                    <input name="datepicker" class="form-control" ngbDatepicker
                                       #datepicker="ngbDatepicker" [autoClose]="true"
                                       (dateSelect)="onDateSelection($event)" [displayMonths]="1"
                                       [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!"
                                       [minDate]="bookingMinDate" [maxDate]="bookingMaxDate">
                                    <ng-template #t let-date let-focused="focused">
                                       <span class="custom-day" [class.focused]="focused"
                                          [class.range]="isRange(date)"
                                          [class.faded]="isHovered(date) || isInside(date)"
                                          (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                          {{ date.day }}
                                       </span>
                                    </ng-template>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label for="check-in-date" class="text-white">Check-in date</label>
                                 <input #dpFromDate type="text" class="form-control text-default"
                                    placeholder="yyyy-mm-dd" name="dpFromDate" (click)="datepicker.toggle()"
                                    [value]="formatter.format(fromDate) | date:'EEE, MMM d, y'" autocomplete="off"
                                    (input)="fromDate = validateInput(fromDate, dpFromDate.value)" required>
                                 <!-- <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar"
                                    type="button"></button>
                              </div> -->
                              </div>

                              <div class="form-group">
                                 <label for="check-out-date" class="text-white">Check-out date</label>
                                 <input #dpToDate type="text" class="form-control text-default"
                                    placeholder="yyyy-mm-dd" name="dpToDate" (click)="datepicker.toggle()"
                                    [value]="formatter.format(toDate) | date:'EEE, MMM d, y'" autocomplete="off"
                                    (input)="toDate = validateInput(toDate, dpToDate.value)" required>
                                 <!-- <div class="input-group-append" (click)="datepicker.toggle()">
                              <button class="btn btn-outline-secondary calendar"
                                 type="button"></button>
                              </div>-->
                              </div>

                              <div class="form-group">
                                 <label for="no-of-guest" class="text-white">{{DiffDate}} night(s) stay</label>
                                 <div data-visible="accommodation,flights" class="input-group guests">
                                    <div data-component="index/guest-errors" data-id="main"
                                       data-view-id="accommodation" role="alert">
                                    </div>
                                    <div class="input-box" (click)="toggleRoomsAndOccupancy()">

                                       <label class="text-default ">
                                          <span class="guests_count">
                                             <span data-adults-count="">{{adults}} adults</span>
                                             <span data-visible="accommodation">
                                                &nbsp;·&nbsp;
                                                <span data-children-count="">{{children}} children</span>
                                             </span>
                                             <span data-visible="accommodation">
                                                &nbsp;·&nbsp;
                                                <span data-room-count="">{{noOfrooms}} room</span>
                                             </span>
                                          </span>
                                       </label>
                                    </div>
                                    <div class="list-holder roomsAndOccupancy" role="region"
                                       aria-label="Rooms and occupancy" *ngIf="roomsAndOccupancy == true">
                                       <div class="item business-text p-4">
                                          <div class="row">
                                             <div class="col-10">

                                             </div>
                                             <div class="col-2">
                                                <button class="common_btn btn_hbreffect2 text-dark sm"
                                                   (click)="toggleRoomsAndOccupancy()">X</button>
                                             </div>
                                          </div>
                                          <div class="clearfix" data-render="">
                                             <div class="group_field group_field-adults">

                                                <div class="row stepper" data-component="InputStepper">
                                                   <div class="col-6 stepper_title-wrapper text-default">
                                                      <label class="stepper_title"
                                                         for="group_adults">Adults</label>
                                                   </div>
                                                   <div class="col-6">
                                                      <input style="display: none" type="number"
                                                         class="stepper_input" data-ref="input-stepper-field"
                                                         id="group_adults" name="group_adults" min="1" max="30"
                                                         value="2" data-group-adults-count=""
                                                         [(ngModel)]="adults">
                                                      <button class="common_btn btn_hbreffect2 text-dark sm" type="button"
                                                         (click)="adults = adults - 1; booking.noOfPersons = adults;"
                                                         [disabled]="adults <=1">
                                                         <span class="button_text">−</span>
                                                      </button>
                                                      <span class="stepper_display text-default"
                                                         aria-hidden="true">{{adults}}</span>
                                                      <button class="common_btn btn_hbreffect2 text-dark sm" type="button"
                                                         (click)="adults = adults + 1; booking.noOfPersons = adults; planDetails"
                                                         [disabled]="(maxOccupancy + children) * noOfrooms <= adults">
                                                         <span class="button_text">+</span>
                                                      </button>
                                                      <!-- <span class="stepper_display" aria-live="assertive" >{{adults}} Adults</span> -->
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="group_field group-children ">
                                                <div class="row stepper" data-component="InputStepper">
                                                   <div class="col-6 stepper_title-wrapper">
                                                      <label class="stepper_title text-default"
                                                         for="group_children">Children</label>
                                                   </div>
                                                   <div class="col-6">
                                                      <input style="display: none" type="number"
                                                         class="stepper_input" data-ref="input-stepper-field"
                                                         id="group_adults" name="group_adults" min="1" max="30"
                                                         value="2" data-group-adults-count="">
                                                      <button class="common_btn btn_hbreffect2 text-dark sm" type="button"
                                                         (click)="children = children - 1; booking.noOfChildren = children;"
                                                         [disabled]="children <=0">
                                                         <span class="button_text">−</span>
                                                      </button>
                                                      <span class="stepper_display text-default"
                                                         aria-hidden="true">{{children}}</span>
                                                      <button class="common_btn btn_hbreffect2 text-dark sm" type="button"
                                                         (click)="children = children + 1; booking.noOfChildren = children; "
                                                         [disabled]="(maxOccupancy + children)* noOfrooms <= children">
                                                         <span class="button_text">+</span>
                                                      </button>
                                                      <!-- <span class="stepper_display" aria-live="assertive" id="group_children_desc">{{children}} Children</span> -->
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="group_field group_field-rooms">
                                                <div class="row stepper" data-component="InputStepper">
                                                   <div class="col-6 stepper_title-wrapper">
                                                      <label class="stepper_title text-default"
                                                         for="no_rooms">Rooms</label>
                                                   </div>
                                                   <div class="col-6">
                                                      <input style="display: none" type="number"
                                                         class="stepper_input" data-ref="input-stepper-field"
                                                         id="group_adults" name="group_adults" min="1" max="30"
                                                         value="2" data-group-adults-count="">
                                                      <button class="common_btn btn_hbreffect2 text-dark sm" type="button"
                                                         (click)="noOfrooms = noOfrooms - 1; booking.noOfRooms = noOfrooms;"
                                                         [disabled]="noOfrooms <=1">
                                                         <span class="button_text">−</span>
                                                      </button>
                                                      <span class="stepper_display text-default"
                                                         aria-hidden="true">{{noOfrooms}}</span>
                                                      <button class="common_btn btn_hbreffect2 text-dark sm" type="button"
                                                         (click)="noOfrooms = noOfrooms + 1; booking.noOfRooms = noOfrooms;"
                                                         [disabled]="maxSelectRoom <= noOfrooms">
                                                         <span class="button_text">+</span>
                                                      </button>
                                                      <!-- <span class="stepper_display" id="no_rooms_desc">{{noOfrooms}} Rooms</span> -->
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>


                        </div>
                     </div>
                      <div class="checking-form">
                        <ul class="checkform">

                          <li class="check-avail">
                            <a (click)="getAvailableRoom()" title="" class="check-status">CHECK AVAILABILITY</a>
                          </li>
                        </ul>
                        <div class="clearfix"></div>
                      </div>
                    <!-- </form> -->
                  </div>

                </div>
                <!--sidebar end-->
              </div>
              <div class="col-lg-8">
                <div class="main-dv">

                  <div class="tab-content" id="myTabContent2">
                    <div class="tab-pane fade" id="th-tab" role="tabpanel" aria-labelledby="th-tab">
                      <div class="tb-detaiils st_4">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let room of rooms;">

                            <div class="room-details">
                              <img *ngIf="room.imageList?.length" src="{{room.imageList[0].url}}" alt="">
                              <img *ngIf="!room.imageList?.length" src="assets/images/logo.jpg" alt="">
                              <div class="room-hd">
                                <h4>FROM
                                  {{room.roomOnlyPrice | currency: token.getProperty().localCurrency.toUpperCase()}}
                                </h4>
                                <h3 style="font-size: 24px;">{{room.name}}</h3>
                              </div>
                              <!-- routerLink="/booking/booking" -->
                              <a title="" (click)="onRoomBooking(room)" class="common_btn btn_hbreffect2 text-dark">BOOK NOW <i
                                  class="la la-arrow-right"></i></a>
                            </div>
                            <!--room-details end-->
                          </div>


                        </div>
                      </div>
                      <!--tb-detaiils end-->
                    </div>
                    <div class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div class="tb-detaiils">
                        <div class="row">
                          <div class="col-lg-6 col-md-6" *ngFor="let room of rooms;">
                            <div class="room_details">
                              <div class="room-caroz">
                                <div class="room_img">
                                  <img *ngIf="room.imageList?.length" src="{{room.imageList[0].url}}" alt="">
                                  <img *ngIf="!room.imageList?.length" src="assets/images/logo.jpg" alt="">
                                  <span
                                    class="price-tag">{{ booking.roomPrice ||room.roomOnlyPrice  | currency: token.getProperty().localCurrency.toUpperCase()}}<b>/night</b></span>
                                </div>
                                <!--room_img end-->

                              </div>
                              <!--room-caroz end-->
                              <div class="room_info">
                                <h3><a href="#" title="">{{room.name}}</a></h3>
                                <ul class="ppt-list">
                                  <li>
                                    <img src="assets/images/icy1.png" alt="">
                                    {{room.maximumOccupancy}} GUESTS
                                  </li>
                                  <li>
                                    <img src="assets/images/icy2.png" alt="">
                                    15 Ft²
                                  </li>
                                </ul>
                                <!--ppt-list end-->
                                <p>{{room.description}} </p>

                                <a title="" (click)="onRoomBooking(room)" class="lnk-default">Book Now <i
                                    class="la la-arrow-right"></i></a>
                              </div>
                              <!--room_info end-->
                            </div>
                            <!--room-details end-->
                          </div>

                        </div>
                      </div>
                      <!--tb-detaiils end-->
                    </div>
                    <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div class="tb-detaiils" *ngFor="let room of rooms;">

                        <div class="tb-row">
                          <div class="room_details">
                            <div class="room-caroz">
                              <div class="room_img p-4">
                                <img *ngIf="room.imageList?.length" src="{{room.imageList[0].url}}" alt="" (click)="open(content, room, room.name )">
                                <img *ngIf="!room.imageList?.length" src="assets/images/logo.jpg" alt=" ">

                                </div>
                              <!--room_img end-->

                            </div>
                            <!--room-caroz end-->
                            <div class="room_info mb-3">

                              <h3><a href="#" title="" style="color: #ce3e3c;">{{room.name}}</a></h3>
                              <ul class="ppt-list">
                                <li>

                                  <img *ngIf="room.maximumOccupancy == 1"  src="assets/images/icons/b1.png" alt="">
                                  <img *ngIf="room.maximumOccupancy == 2"  src="assets/images/icons/b2.png" alt="">
                                  <img *ngIf="room.maximumOccupancy == 3"  src="assets/images/icons/b3.png" alt="">
                                  <img *ngIf="room.maximumOccupancy == 4"  src="assets/images/icons/b4.png" alt="">
                                  <img *ngIf="room.maximumOccupancy == 5" src="assets/images/icons/b5.png" alt="">
                                  <img *ngIf="room.maximumOccupancy == 6" src="assets/images/icons/b6.png" alt="">
                                  <span *ngIf="room.maximumOccupancy > 6"><img src="assets/images/icons/b1.png" alt=""></span>
                                  {{room.maximumOccupancy}} GUESTS
                                </li>
                                <li *ngIf="room.ratesAndAvailabilityDtos !== null">
                                  <p>Available: {{room.ratesAndAvailabilityDtos[0].noOfAvailable}}
                                    room<span *ngIf="room.ratesAndAvailabilityDtos[0].noOfAvailable > 1">s</span>
                                  </p>
                                </li>
                              </ul>

                              <div class="fct-dv" *ngIf="room.ratesAndAvailabilityDtos !== null">

                              </div>
                              <div *ngIf="room.ratesAndAvailabilityDtos == null">
                                <h2>Room Not Available!</h2>
                              </div>

                            </div>
                            <div class="clearfix"></div>
                            <div class="row facilities m-4">
                              <div class="col-12" *ngIf="room.roomFacilities?.length">
                                <h4 style="font-size: 20px;font-weight: bold;">Facilities</h4>
                                <div class="d-flex flex-wrap">
                                  <div class=" " style="width:170px;" *ngFor="let item of room.roomFacilities">
                                    <div class="media pt-3">
                                      <img [src]="item.imageUrl" width="25px" height="25px" alt="" class="mr-2 rounded">
                                      <div class="media-body pb-3 p-1 mb-0 small lh-125 ">
                                     <div class="d-block"> <strong>{{item.name}}</strong></div>

                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="row">
                              <div class="col-12  plan_layout">
                                <div class="fct-dv p-3">
                                  <h5 class=" font-weight-bold" style="font-size: 20px;color: #ce3e3c;"><b>Select Plan:</b>
                                  </h5>
                                  <div class="row" *ngFor="let rates of room.ratesAndAvailabilityDtos; let r= index; ">
                                    <div class="col-12" *ngIf="room.id == rates.roomId && r<1">
                                      <div class="row" *ngFor="let plan of rates.roomRatePlans; let p= index;">
                                        <div class="col-6 pull-left">
                                          <h5 class="font-weight-bold" style="font-size: 20px;"> {{ plan.name }}</h5>
                                          <div>Extra person charge
                                            {{ plan.extraChargePerPerson | currency: currency:'symbol':'1.2-2'}}</div>
                                          <div>Extra child charge
                                            {{ plan.extraChargePerChild | currency: currency:'symbol':'1.2-2'}}</div>

                                          <p> {{ plan.description }}</p>

                                        </div>
                                        <div class="col-6 text-right">
                                          <h5 class="mb-0 font-weight-bold" style="font-size: 20px; color: #2f683b;">
                                            {{ (plan.amount * DiffDate * noOfrooms)   | currency: currency:'symbol':'1.2-2' }}
                                          </h5>
                                          <!-- <div style="color: #f5365c; font-weight: bold;"
                                            *ngIf="hasPercentage(room.roomOnlyPrice, plan.amount) == true">
                                            ({{ ((room.roomOnlyPrice -
                                             plan.amount)/room.roomOnlyPrice) * 100 | number:'1.2-2' }}%
                                            Discount)</div> -->
                                          <b class="small-text font-weight-bold"> {{DiffDate}} nights,
                                            {{booking.noOfPersons}}
                                            adults</b><br>
                                          <button
                                          [disabled]="(plan.amount * DiffDate * noOfrooms)===0"
                                          ngbButton class="common_btn btn_hbreffect2 text-dark mb-4"(click)="
                                        onPlanSelected(plan, room);
                                        booking.roomName = rates.roomName;
                                     selectedRoomName = rates.roomName;
                                     booking.roomType = rates.roomName;
                                     maxSelectRoom = rates.noOfAvailable;
                                     maxOccupancy = room.maximumOccupancy;
                                        onRoomBook(room.id, i);
                                        booking.roomId = room.id;
                                        selectedRoomMaximumOccupancy = room.maximumOccupancy;
                                        selectedRoomAvailableNumber = room.noOfRooms;
                                        checkAvailabilityStatus = true;
                                        bookRoomNow() ">Book Now</button>

                                        </div>

                                      </div>
                                      <div *ngIf="rates.roomRatePlans.length == 0">
                                        <p>Plan Not Available. Without plan you can't book room.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                  <div class="col-12 mb-4" *ngIf="room.description">
                                    <h6 class="font-weight-bold">Description</h6>
                                    <div class=" mt-2" [innerHtml]="room.description"></div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--room_info end-->

                          </div>








                          <!--room-details end-->
                        </div>
                        <!--tb-row end-->
                      </div>
                      <!--tb-detaiils end-->
                    </div>
                  </div>

                </div>
                <!--main-dv end-->
              </div>
            </div>
          </div>
          <!--tab-contentt end-->
        </div>
      </div>
     </div>
      </div>
    </div>
    <!--page-content end-->
  </div>
</section>
<!--main-content end-->




<ng-template #content let-modal #room>
  <div class="modal-header">
     <h4 class="modal-title" id="modal-basic-title">
        {{modalTitle}}
     </h4>
     <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"> × </span>
     </button>
  </div>
  <div class="modal-body">
     <div class="room_details row " *ngIf="modalData.ratesAndAvailabilityDtos?.length">
        <div class="room-caroz col-lg-12">
           <div class="room_img" *ngIf="room">

              <ngx-slick-carousel class="carousel listing-slider mfp-gallery-container margin-bottom-0 ml-4 mr-4"
                 #slickModal="slick-carousel" [config]="modalSlideConfig">
                 <div ngxSlickItem *ngFor="let gallery of modalData.imageList" class="slide">
                    <a *ngIf="gallery.url != null" [ngStyle]="{'background-image': 'url(' + gallery.url + ')'}"
                       class="item mfp-gallery cover" title="{{gallery.name}}"></a>
                    <a *ngIf="gallery.url == null"
                       [ngStyle]="{'background-image': 'url(assets/images/app-logo.png)'}"
                       class="item mfp-gallery cover" title="{{gallery.name}}"></a>
                 </div>
              </ngx-slick-carousel>
           </div>

        </div>
        </div>

     </div>


  <div class="modal-footer">
     <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
        Close
     </button>
  </div>
</ng-template>
