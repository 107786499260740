<div class="banner_area_type3 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="banner_section_title align-center">
                  <div class="section_sub_title">
                      <p>Booking</p>
                  </div>
                  <h1>Find Your Desire Room</h1>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink='/'>
                              Home
                          </a>
                      </li>
                      <li class="active">
                        Booking
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
<section class="main-content bkng">
  <div class="container">
    <div class="page-content">
      <div class="booking-tabs-list">
        <ul class="nav nav-tabs" id="myTab3" role="tablist">
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span>CHOSE ROOM</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="booking_tab">
              <span class="nom"></span> BOOKING</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span> CHECKOUT</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"><span class="nom"></span> THANK YOU</a>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="sidebar">
            <!-- <div class="avail-sec"> -->
              <!-- <form> -->
                              <!-- </form> -->
              <div class="widget bg-secondary my-2">
                <h4 class="bg p-4"><i class="fa fa-calendar-check-o"></i> Your Booking
                  Details</h4>
                <div class="row px-4">
                  <div class="col-6">
                    <h6>Check-in</h6>
                    <p>{{booking.fromDate | date:'EEE, MMM d, y'}}</p>
                  </div>
                  <div class="col-6">
                    <h6>Check-out</h6>
                    <p>{{booking.toDate | date:'EEE, MMM d, y'}}</p>

                  </div>
                </div>
                <div class="row px-4">
                  <div class="col-12">
                    <h6>Total length of stay:</h6>
                    <p>{{DiffDate}} Night(s)</p>
                    <h6>You selected:</h6>
                    <p>{{booking.roomType}}</p>
                  </div>
                </div>
                <div class="row px-4 pb-4">
                  <div class="col-12">
                    <h6>Guests:</h6>
                    <div>{{booking.noOfPersons}} Adult(s) </div><span *ngIf="booking.noOfChildren > 0" >{{booking.noOfChildren}} Child(s) </span>
                    <!-- <h6>You selected:</h6>
                    <p>{{booking.roomType}}</p> -->
                  </div>
                </div>
              </div>

              <div class="widget bg-secondary my-4">
                <h5 class="card-title bg p-4 pb-5 w-100">Price<span class="pull-right text-right">
                    {{ booking.totalAmount | currency: currency :'symbol':'1.2-2' }}
                    <br>
                    <p class="text-white">Total Payable Amount</p>
                  </span>
                </h5>

                <div class="row px-4">
                  <div class="col-12">
                    <h6>Total length of stay:</h6>
                    <p>{{ DiffDate }} Night(s)</p>
                    <h6>You selected:</h6>
                    <p>{{ booking.roomName }}</p>
                    <h6>Room price:</h6>
                    <p>{{booking.roomPrice | currency: currency:'symbol':'1.2-2' }} x {{DiffDate}}</p>
                    <h6 *ngIf="discountPercentage"><b>Discount ({{booking.discountPercentage}}%):
                    </b>{{booking.discountAmount | currency: currency:'symbol':'1.2-2'}}
                  </h6>
                    <h6>Tax Amount ({{this.booking.taxPercentage}}%):</h6>
                    <p *ngIf="property?.taxDetails">{{ property.taxDetails[0].name }} & charges
                      {{  (this.booking.netAmount * this.booking.taxPercentage) / 100 | currency: currency:'symbol':'1.2-2' }}
                    </p>

                    <div *ngIf="booking.noOfExtraPerson > 0">
                      <h6 class="">Extra Person charges</h6>
                      <p class="">
                         {{ booking.extraPersonCharge | currency: currency:'symbol':'1.2-2' }}
                      </p>
                     </div>

                     <div *ngIf="booking.noOfExtraChild > 0">

                      <h6 class="">Extra Child charges</h6>
                      <p class="">
                         {{ booking.extraChildCharge | currency: currency:'symbol':'1.2-2' }}
                      </p>
                     </div>
                  </div>
                </div>
              </div>
<!-- Booking Summary / End -->

            <!-- </div> -->
            <div class="extra_info"></div>
          </div>
        </div>

        <div class="col-lg-8 col-md-8 padding-right-30">
          <div *ngIf="loader" class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 100%"
            ></div>
          </div>

          <form
            class="form"
            (ngSubmit)="orderForm.form.valid && onSubmit(orderForm)"
            #orderForm="ngForm"
            *ngIf="!bookingConfirmed"
          >

            <h4 class="mb-4">Booking Checkout</h4>

            <div class="row mb-4">

							<div class="col-md-6" *ngIf="verified  || customerExist">
								<div class="form-group">
									<label class="form-label" for="firstName">First Name*</label>
									<input type="text" placeholder="First Name" id="firstName"
										class="form-control form-control-alternative" [(ngModel)]="booking.firstName"
										#firstName="ngModel" name="firstName" required>
									<span class="text-danger"
										*ngIf="(firstName.touched || orderForm.submitted) && firstName.errors?.required">
										First Name is required
									</span>
								</div>
							</div>
							<div class="col-md-6" *ngIf="verified  || customerExist">
								<div class="form-group">
									<label class="form-label" for="lastName">Last Name*</label>
									<input type="text" placeholder="Last Name" id="lastName"
										class="form-control form-control-alternative" name="lastName"
										[(ngModel)]="booking.lastName" #lastName="ngModel" required>
									<span class="text-danger"
										*ngIf="(lastName.touched || orderForm.submitted) && lastName.errors?.required">
										Last Name is required
									</span>
								</div>
							</div>
							<div class="col-md-12" *ngIf="!verified  && !customerExist">
								<div class="btn-group">
									<Label>Verification Method</Label>
									<div class="form-check form-check-inline ml-3">
										<input class="form-check-input" type="radio" name="verifyOpt" id="verifyOpt1"
											[(ngModel)]="verifyOption" #verifyOpt1="ngModel"
											(ngModelChange)="clickEmail()" value="email" [readonly]="lookup">
										<label class="form-check-label" for="verifyOpt1">Email</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="verifyOpt"
											[(ngModel)]="verifyOption" #verifyOpt2="ngModel"
											(ngModelChange)="clickPhone()" id="verifyOpt2" value="sms"
											[readonly]="lookup">
										<label class="form-check-label" for="verifyOpt2">SMS</label>
									</div>

								</div><button class="common_btn btn_hbreffect2 text-dark ml-3" id="reload"
									(click)="onReload()" [readonly]="">Reset</button>
							</div>
							<div class="col-md-6" *ngIf="verified ">
								<div class="form-group">
									<label class="form-label" for="email">Email*</label>
									<input type="email" placeholder="E-mail" id="email"
										class="form-control form-control-alternative w-100" name="email"
										[(ngModel)]="booking.email" #email="ngModel" required email
										[readonly]="booking.email !== '' && verifyOption == 'email' ">
									<span class="text-danger"
										*ngIf="(email.touched || orderForm.submitted) && email.errors?.required">
										Email is required
									</span>
									<span class="text-danger"
										*ngIf="(email.touched || orderForm.submitted) && email.errors?.email">
										Enter a valid email
									</span>
								</div>
							</div>
							<div class="col-md-6" *ngIf="verified ">
								<div class="form-group">
									<label class="form-label" for="mobile">Phone*</label>
									<br>
									<input *ngIf="booking.mobile == ''" type="text" ng2TelInput
										[ng2TelInputOptions]="{initialCountry: 'in'}" (hasError)="phoneHasError($event)"
										(ng2TelOutput)="getNumber($event)" class="form-control form-control-alternative"
										id="mobile" name="mobile" class="form-control form-control-alternative w-100"
										required>
									<input *ngIf="booking.mobile !== ''" type="text"
										class="form-control form-control-alternative w-100" id="mobile" name="mobile"
										[(ngModel)]="booking.mobile" #mobile="ngModel" required
										[readonly]="booking.mobile !== '' && verifyOption == 'sms'">
									<span class="text-danger" *ngIf="!mobileHasError"> Invalid Phone number </span>
									<!-- <span class="text-danger"
										*ngIf="(mobile.touched || orderForm.submitted) && mobile.errors?.required">
										Phone is required
									</span> -->
								</div>
							</div>



							<div class="col-md-6" *ngIf="verifyOption == 'email' && !verified ">
								<div class="form-group">
									<!-- <label class="form-label" for="email">Email</label> -->
									<input type="email" placeholder="E-mail"
										class="form-control form-control-alternative w-100" id="email" name="email"
										[(ngModel)]="booking.email" #email="ngModel" required email [readonly]="lookup">
									<span class="text-danger"
										*ngIf="(email.touched || orderForm.submitted) && email.errors?.required">
										Email is required
									</span>
									<span class="text-danger"
										*ngIf="(email.touched || orderForm.submitted) && email.errors?.email">
										Enter a valid email
									</span>
								</div>
							</div>
							<div class="col-md-6" *ngIf="verifyOption == 'sms' && !verified ">
								<div class="form-group">
									<!-- <label class="form-label" for="mobile">Phone</label> -->
									<input type="text" [(ngModel)]="booking.mobile" (ngModelChange)="mobileTextChange()"
										ng2TelInput [ng2TelInputOptions]="{initialCountry: 'in'}"
										(hasError)="phoneHasError($event)" (ng2TelOutput)="getNumber($event)"
										id="mobile" class="form-control form-control-alternative w-100" id="mobile"
										name="mobile" required [readonly]="lookup">
									<br>
									<span class="text-danger" *ngIf="!mobileHasError"> Invalid Phone number </span>
									<!-- <span class="text-danger"
										*ngIf="(mobile.touched || orderForm.submitted) && mobile.errors?.required">
										Phone is required
									</span> -->
								</div>
							</div>
							<div class="col-md-6" *ngIf="verifyOption == 'sms' && !verified ">
								<button *ngIf="this.booking.mobile.length >4 && !isVerified" (click)="onVerified()"
									class="common_btn btn_hbreffect2 text-dark ml-3 verify">Verify</button>
							</div>
							<!-- *ngIf="!verified && (order.mobile != null || order.email != null)" -->
							<!-- <div class="col-md-12" *ngIf="!verified && !customerExist || order.email != '' && order.mobile != '' " > -->
							<div class="col-md-12"
								*ngIf="lookup && !customerExist && !verified &&  (booking.email != '' || booking.mobile != '') ">
								<div class="form-group">
                  <label class="text-danger">Seems you do not have any prior booking with us, click on Get OTP button and proceed with the bookings.</label>
									<button class="common_btn btn_hbreffect2 text-dark ml-3"
										(click)="checkCustomer()">{{sendBtn}}</button>

								</div>
							</div>
							<div class="col-md-6"
								*ngIf="!lookup && !customerExist && (booking.email != '' || isVerified && mobileHasError)">
								<div class="form-group">
									<!-- <label></label> -->
									<button class="common_btn btn_hbreffect2 text-dark" (click)="customerLookup()"
										[disabled]="(booking.email == '' || !orderForm.valid ) && !mobileHasError ">Search</button>
								</div>
							</div>
							<div class="col-md-6" *ngIf="verificationSend && !verified">
								<div class="form-group">
									<label>Verification Code Sent! Enter the code below</label>
									<input type="text" placeholder="Verification Code" name="verifyCode" id="verifyCode"
										[(ngModel)]="verificationCode" #verifyCode="ngModel" minlength="6" maxlength="6"
										class="form-control form-control-alternative">
								</div>
							</div>
							<div class="col-md-3" *ngIf="verificationSend && !verified">
								<div class="form-group">
									<label>Click to verify</label>
									<button (click)="varificationSend()" class="common_btn btn_hbreffect2 text-dark"
										[disabled]="verificationCode == '' || verificationCode == null || !orderForm.valid">Verify</button>
								</div>
							</div>
							<div class="col-md-12" *ngIf="verified && verifySuccessMessage ">
								<p class="text-success">Verification Success!</p>
							</div>
							<div class="col-md-12" *ngIf="verifiedPending && verifySuccessMessage">
								<p class="text-warning">Verification Error!</p>
							</div>
						</div>
						<h4 class="mb-3 mt-4" *ngIf="verified">Booking Overview</h4>

						<div class="row" *ngIf="verified">
							<div class="col-md-12">
								<label class="form-label" for="mobile">Special Notes</label>
								<textarea type="text" placeholder="Special Notes"
									class="form-control form-control-alternative mb-3" id="note" name="note"
									[(ngModel)]="booking.notes"></textarea>
								<p class="text-muted">Type your requests in English here – we'll share them with the
									property.
									Special requests cannot be guaranteed, but the property will do its best to meet
									your needs.
									You can always make a special request after your booking is complete!</p>
							</div>
							<div class="col-md-12">
								<label class="form-label" for="mobile">Promotion</label>
								<!-- <input type="text" placeholder="Promo Code"
									class="form-control form-control-alternative mb-3" id="note" name="promoCode"
									[(ngModel)]="promoCode" />
							<button  class="btn btn-primary" type="button"  (click)="applyPromoCode()" [disabled]="promoCode == null">Apply</button>
							 -->
								<ul class="form-check" ngbRadioGroup name="radioBasic" [(ngModel)]="promoCode">
									<li>
										<div>
											<label ngbButtonLabel class="form-check-label text-default "
												style="padding: 0; white-space: normal ; " (click)="applyPromoCode('')">
												<input ngbButton type="radio" class="form-check-input" [value]="">
												No offer</label>
											<!-- <button class="btn btn-primary" type="button" (click)="applyPromoCode(offer)">Apply Promo</button> -->
										</div>
									</li>
									<li *ngFor="let offer of businessOfferDto">
										<div>
											<label ngbButtonLabel class="form-check-label text-default "
												style="padding: 0; white-space: normal ; "
												(click)="applyPromoCode(offer)">
												<input ngbButton type="radio" class="form-check-input" [value]="offer">
												{{ offer.name }} Code: {{offer.couponCode}}</label>
										</div>
									</li>
								</ul>
								<ngb-alert class="mt-3" *ngIf="promoMessage != ''" [type]="'info'"
									(close)="promoMessage == ''" (click)="promoMessage = ''">
									<strong>Promo Code: </strong> {{promoMessage}}</ngb-alert>
							</div>
							<div class="col-md-6">
								<ul class="summery">
									<!-- <li><b>Date: </b> <span> {{booking.fromDate  | date:'EEE, MMM d, y'}} - {{booking.toDate | date:'EEE, MMM d, y'}}</span></li> -->
									<!-- <li><b>Number of Night(s): </b> <span> {{DiffDate}}</span></li> -->
									<li><b>Room Type: </b><span>{{booking.roomName}}</span></li>
									<li><b>No Of Person(s): </b><span>{{booking.noOfPersons}}</span></li>
									<li><b>No Of Extra Person(s): </b><span>{{booking.noOfExtraPerson}}</span></li>
									<li><b>Extra Person charges: </b><span>  {{ booking.extraPersonCharge | currency: currency:'symbol':'1.2-2' }}</span></li>

									<li><b>Net Amount: </b> <span>
											{{booking.netAmount | currency: currency:'symbol':'1.2-2' }}
										</span></li>
									<li *ngIf="discountPercentage"><b>Discount: </b>{{discountPercentage}}%</li>
									<li>
										<h5>Total Amount: <span>
												{{booking.totalAmount | currency: currency:'symbol':'1.2-2' }} </span>
										</h5>
									</li>
									<!-- <li class="total-costs"><b>Total Cost: </b><span> 546.00</span></li> -->
								</ul>
							</div>
							<div class="col-md-6">
								<ul class="summery">
									<li><b>No Of Room(s): </b><span>{{booking.noOfRooms}}</span></li>
									<li><b>No Of Child: </b><span>{{booking.noOfChildren}}</span></li>
									<li><b>No Of Extra Child(s): </b><span>{{booking.noOfExtraChild}}</span></li>
									<li><b>Extra Child charges: </b><span>{{ booking.extraChildCharge | currency: currency:'symbol':'1.2-2' }}</span></li>

									<li><b>Room Price: </b><span>
											{{booking.roomPrice | currency: currency:'symbol':'1.2-2' }} </span>
									</li>
									<li><b>Tax Amount ({{this.taxPercentage}}%): </b>
										<span> {{  booking.gstAmount | currency: currency:'symbol':'1.2-2' }}</span>
									</li>
									<li *ngIf="discountPercentage"><b>Discount Amount:
										</b>{{booking.discountAmount | currency: currency:'symbol':'1.2-2'}}
									</li>



									<!-- <li class="total-costs"><b>Total Cost: </b><span> 546.00</span></li> -->
								</ul>
							</div>
							<!-- <div class="col-md-12">
								<button class="btn btn-primary" type="button"
									[disabled]="!orderForm.form.valid  || paymentLoader || submitButtonDisable"
									(click)="onCashPaymentSubmit()">Confirm</button>

							</div> -->
              <div class="col-md-12 pt-2">
                <button class="common_btn btn_hbreffect2 text-dark " type="button"
                  [disabled]="!orderForm.form.valid  || paymentLoader || submitButtonDisable"
                  (click)="onCheckOut()">Checkout</button>

              </div>
						</div>
          </form>
        </div>
        <!-- <form action="">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <input type="text" placeholder="Full Name"
                                        class="form-control form-control-alternative">
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <input type="text" placeholder="Bank Name"
                                        class="form-control form-control-alternative">
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <input type="text" placeholder="A/C Number"
                                        class="form-control form-control-alternative">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <input type="text" placeholder="A/C Type"
                                        class="form-control form-control-alternative">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <input type="text" placeholder="Swift Code"
                                        class="form-control form-control-alternative">
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <button class="btn btn-primary" type="button"
                                      [disabled]="!orderForm.form.valid  || loader"
                                      (click)="onCashPaymentSubmit(content)">Click n Collect</button>
                                  </div>
                                </div>
                              </form>
                            </div> -->
      </div>
    </div>
  </div>
</section>
<!--main-content end-->
